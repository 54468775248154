export const fieldValidation = event => {
	const onlyLettersRegex = /^[a-zA-Z\s]*$/;
	const onlyNumbersRegex = /^[0-9()+\s]*$/;
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
	const urlRegex =
		/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
	const inputName = event.target.name;
	const inputValue = event.target.value;

	let validation = false;

	switch (inputName) {
		case "email":
			if (emailRegex.test(inputValue)) {
				validation = true;
			}
			break;
		case "phone":
			if (onlyNumbersRegex.test(inputValue) && inputValue.length > 3)
				validation = true;
			break;
		case "websiteUrl":
			if (urlRegex.test(inputValue)) validation = true;
			break;
		default:
			if (onlyLettersRegex.test(inputValue) && inputValue.length > 0)
				validation = true;
	}

	if (!validation) {
		event.target.classList.add("input-error");
		event.target.classList.remove("input-success");
		event.target.previousSibling.classList.remove("show-icon");
		event.target.nextSibling.classList.add("show-icon");
		event.target.nextSibling.nextSibling.classList.add("show-error");
	} else {
		event.target.classList.remove("input-error");
		event.target.classList.add("input-success");
		event.target.previousSibling.classList.add("show-icon");
		event.target.nextSibling.classList.remove("show-icon");
		event.target.nextSibling.nextSibling.classList.remove("show-error");
	}
	return validation;
};

export const formValidation = () => {
	let inputs = Array.from(document.getElementsByTagName("input"));
	const onlyLettersRegex = /^[a-zA-Z\s]*$/;
	const onlyNumbersRegex = /^[0-9()+\s]*$/;
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
	const urlRegex =
		/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

	const errors = [];
	for (let input of inputs) {
		const inputName = input.name;
		const inputValue = input.value;
		switch (inputName) {
			case "email":
				if (!emailRegex.test(inputValue)) errors.push(inputName);
				break;
			case "phone":
				if (!onlyNumbersRegex.test(inputValue) || inputValue.length <= 3)
					errors.push(inputName);
				break;
			case "websiteUrl":
				if (!urlRegex.test(inputValue)) errors.push(inputName);;
				break;
			default:
				if (!onlyLettersRegex.test(inputValue) || inputValue.length < 1)
					errors.push(inputName);
		}

		if (errors.includes(inputName)) {
			input.classList.add("input-error");
			input.classList.remove("input-success");
			input.previousSibling.classList.remove("show-icon");
			input.nextSibling.classList.add("show-icon");
			input.nextSibling.nextSibling.classList.add("show-error");
		} else {
			input.classList.remove("input-error");
			input.classList.add("input-success");
			input.previousSibling.classList.add("show-icon");
			input.nextSibling.classList.remove("show-icon");
			input.nextSibling.nextSibling.classList.remove("show-error");
		}
	}
	return errors.length === 0;
};
