import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import StepperProgressBar from "./StepperProgressBar";
import StepperTitle from "./StepperTitle";
import StepperForm from "./StepperForm";
import DownloadNow from "./DownloadNow";
import Navbar from "./Navbar";
import { fieldValidation, formValidation } from "../helpers/formValidation";
import "./Stepper.css";

const Stepper = props => {
	
	const [step, setStep] = useState(1);
	const [contactDetails, setContactDetails] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		companyName: "",
		position: "",
	});

	const handleChange = event => {
		setContactDetails({
			...contactDetails,
			[event.target.name]: event.target.value,
		});
	};

	const backStep = () => {
		setStep(step - 1);
	};

	const forwardStep = () => {
		if (handleFormValidation()) setStep(step + 1);
	};

	const handleInputValidation = event => {
		fieldValidation(event);
	};

	const handleFormValidation = () => {
		return formValidation();
	};

	const handleKeyDown = event => {
		if (event.key === "Enter") {
			step === 3 ? handleSubmit(event) : forwardStep();
		}
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (handleFormValidation()) {
			forwardStep();
			submitClickGA();
			fetch("https://hooks.zapier.com/hooks/catch/11817844/bg6ij29/", {
				method: "POST",
				body: JSON.stringify(contactDetails),
			})
				.then(res => res.json())
				.then(data => {
					console.log(data.status);
					setContactDetails({
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						companyName: "",
						position: "",
					});
				});
		}
	};

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `Form step: ${step < 4 ? step : "download now"}`,
			title: step < 4 ? "form" : "download now",
		});
	});

	const submitClickGA = () => {
		ReactGA.event({
			action: "form_submitted",
			category: "submission",
			label: "Next Step/Submit"
		});
	};

	return (
		<div className="stepper-container">
			{step < 4 && (
				<img
					src={require("../assets/icons/close.png")}
					alt=""
					onClick={props.handleClose}
				></img>
			)}
			{step < 4 ? (
				<StepperProgressBar step={step} />
			) : (
				<Navbar downloadNavbar={true} />
			)}
			<StepperTitle step={step} />
			{step < 4 ? (
				<StepperForm
					step={step}
					setStep={setStep}
					contactDetails={contactDetails}
					handleChange={handleChange}
					backStep={backStep}
					forwardStep={forwardStep}
					handleInputValidation={handleInputValidation}
					handleSubmit={handleSubmit}
					handleKeyDown={handleKeyDown}
				/>
			) : (
				<DownloadNow />
			)}
		</div>
	);
};

export default Stepper;
