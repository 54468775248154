import React from "react";
import { motion } from "framer-motion";
import DialogStepper from "./DialogStepper";
import "./Header.css";

const Header = () => {
	
	return (
		<div className="header-container" id="header">
			<motion.section
				initial={{ x: "-150" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 2, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<h1>Mobility Trends: 2022</h1>
				<h1>güil Mobility Ventures</h1>
				<p>
					Get the definitive guide to today's mobility industry and some of the
					best startups that are transforming our world, with insights from the
					different verticals that make up the mobility space.
				</p>
				<DialogStepper component={"free-download-header"} />
			</motion.section>
			<motion.img
				src={require("../assets/images/header-picture.png")}
				alt=""
				initial={{ x: "150" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 2, bounce: 0.3 }}
				viewport={{ once: true }}
			/>
		</div>
	);
};

export default Header;
