import React, {useState, forwardRef} from "react";
import ReactGA from "react-ga";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Stepper from "./Stepper";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} timeout={1250} />;
});

const DialogStepper = props => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		freeDownloadButtonClickGA();
	};

	const handleClose = () => {
		setOpen(false);
		closeClickGA();
	};

	const closeClickGA = () => {
		ReactGA.send({
			hitType: "pageview",
			page: "Home",
			title: "Home",
		});

		ReactGA.event({
			action: "form-closed",
			category: "click",
			label: "X",
		});
	};

	const freeDownloadButtonClickGA = () => {
		ReactGA.event({
			action: props.component,
			category: "click",
			label: "Free Download",
		});
	};

	return (
		<div>
			<button onClick={handleClickOpen}>Free Download</button>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<Stepper handleClose={handleClose} />
			</Dialog>
		</div>
	);
};

export default DialogStepper;
