import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import "./DownloadNow.css";
import samplePDF from "../assets/documents/whitebook.pdf";

const DownloadNow = () => {

	const handleDownloadClick = () => {
		ReactGA.event({
			action: "ebook_downloaded",
			category: "download",
			label: "Downnload Now"
		});
	};

	return (
		<div className="download-now-container">
			<img src={require("../assets/images/ebook-cover.png")} alt="" />
			<h3>güil Ventures - Mobility Trends 2022</h3>
			<Link
				to={samplePDF}
				target="_blank"
				download="guil-ventures-whitepaper-2022.pdf"
			>
				<button onClick={handleDownloadClick}>Download Now</button>
			</Link>
		</div>
	);
};

export default DownloadNow;
