import React from "react";
import "./StepperForm.css";

const StepperForm = props => {
	
	return (
		<div className="stepper-form-container"
			 onKeyDown={props.handleKeyDown}>
			<h5>All fields are required</h5>
			<form onSubmit={e => e.preventDefault()}>
				{props.step === 1 && (
					<fieldset>
						<div>
							<img
								src={require("../assets/icons/check-green.png")}
								alt=""
								className="success-icon"
							/>
							<input
								type="text"
								placeholder="First Name"
								name="firstName"
								value={props.contactDetails.firstName}
								onChange={props.handleChange}
								onBlur={props.handleInputValidation}
							/>
							<img
								src={require("../assets/icons/warning.png")}
								alt=""
								className="error-icon"
							/>
							<p>Complete this field</p>
						</div>
						<div>
							<img
								src={require("../assets/icons/check-green.png")}
								alt=""
								className="success-icon"
							/>
							<input
								type="text"
								placeholder="Last Name"
								name="lastName"
								value={props.contactDetails.lastName}
								onChange={props.handleChange}
								onBlur={props.handleInputValidation}
							/>
							<img
								src={require("../assets/icons/warning.png")}
								alt=""
								className="error-icon"
							/>
							<p>Complete this field</p>
						</div>
					</fieldset>
				)}
				{props.step === 2 && (
					<fieldset>
						<div>
							<img
								src={require("../assets/icons/check-green.png")}
								alt=""
								className="success-icon"
							/>
							<input
								type="text"
								placeholder="Email"
								name="email"
								value={props.contactDetails.email}
								onChange={props.handleChange}
								onBlur={props.handleInputValidation}
							/>
							<img
								src={require("../assets/icons/warning.png")}
								alt=""
								className="error-icon"
							/>
							<p>Complete this field</p>
						</div>
						<div>
							<img
								src={require("../assets/icons/check-green.png")}
								alt=""
								className="success-icon"
							/>
							<input
								type="text"
								placeholder="Phone Number"
								name="phone"
								value={props.contactDetails.phone}
								onChange={props.handleChange}
								onBlur={props.handleInputValidation}
							/>
							<img
								src={require("../assets/icons/warning.png")}
								alt=""
								className="error-icon"
							/>
							<p>Complete this field</p>
						</div>
					</fieldset>
				)}
				{props.step === 3 && (
					<fieldset>
						<div>
							<img
								src={require("../assets/icons/check-green.png")}
								alt=""
								className="success-icon"
							/>
							<input
								type="text"
								placeholder="Company Name"
								name="companyName"
								value={props.contactDetails.companyName}
								onChange={props.handleChange}
								onBlur={props.handleInputValidation}
							/>
							<img
								src={require("../assets/icons/warning.png")}
								alt=""
								className="error-icon"
							/>
							<p>Complete this field</p>
						</div>

						<div>
							<img
								src={require("../assets/icons/check-green.png")}
								alt=""
								className="success-icon"
							/>
							<input
								type="text"
								placeholder="Position"
								name="position"
								value={props.contactDetails.position}
								onChange={props.handleChange}
								onBlur={props.handleInputValidation}
							/>
							<img
								src={require("../assets/icons/warning.png")}
								alt=""
								className="error-icon"
							/>
							<p>Complete this field</p>
						</div>
					</fieldset>
				)}
			</form>
			<section>
				{props.step !== 1 && (
					<button className="first-button" onClick={props.backStep}>
						Back
					</button>
				)}
				<button
					className="second-button"
					onClick={props.step === 3 ? props.handleSubmit: props.forwardStep}
				>
					Next Step
				</button>
			</section>
		</div>
	);
};

export default StepperForm;
