import React from 'react';
import { motion } from "framer-motion";
import "./Description.css";

const Description = () => {
	
	return (
		<div className="description-container">
			<motion.section 
                className="description-title-container"
                initial={{ x: '-150' }}
				whileInView={{ x: 0 }}
				transition={{type: 'spring', duration:2, bounce: 0.3}}
				viewport={{ once: true }}>
				<h1>Mobility Trends</h1>
			</motion.section>
            <motion.section 
                className="description-text-container"
                initial={{ x: '150' }}
				whileInView={{ x: 0 }}
				transition={{type: 'spring', duration:2, bounce: 0.3}}
				viewport={{ once: true }}>
                <p>
                    As the mobility sector continues to evolve, it is changing how we live our daily lives. 
                    This report provides an overview of the market and presents some of the most interesting insights from different perspectives.
                </p>
                <p>
                    Get a first look at some of the most exciting developments across industries, such as autonomous and electric vehicles. 
                    Discover some of the most promising startups that are building products and services that will reshape the mobility sector.
                </p>
	        </motion.section>
		</div>
	);
};

export default Description;
