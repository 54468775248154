import React from "react";
import { motion } from "framer-motion";
import DialogStepper from "./DialogStepper";
import "./CallToAction.css";

const CallToAction = () => {
	
	return (
		<div className="callToAction-container">
			<motion.section
				className="callToAction-text-container"
				initial={{ x: "-150" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 2, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<h1>Would you like to download this report?</h1>
				<p>
					You can download for free a complete and comprehensive report that
					reviewed over 3,000 startups around the world. Just click on the
					button and fill in your details.{" "}
				</p>
			</motion.section>
			<motion.section
				className="callToAction-button-container"
				initial={{ x: "150" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 2, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<DialogStepper component={"free-download-call-to-action"} />
			</motion.section>
		</div>
	);
};

export default CallToAction;
