import React, { useState } from "react";
import ReactGA from "react-ga";
import { HashLink } from "react-router-hash-link";
import DialogStepper from "./DialogStepper";
import "./Navbar.css";

const Navbar = props => {

	const [color, setColor] = useState(false);
	const changeColor = () => {
		window.scrollY >= 90 ? setColor(true) : setColor(false);
	};
	window.addEventListener("scroll", changeColor);

	const emailLinkClickGA = () => {
		ReactGA.event({
			action: "email-link-clicked",
			category: "email",
			label: "hello@guil.cl"
		});
	};

	return (
		<div
			className={
				color && !props.downloadNavbar
					? "navbar-container navbar-bg"
					: props.downloadNavbar
					? "download-navbar-container"
					: "navbar-container"
			}
		>
			<nav>
				{!props.downloadNavbar ? (
					<HashLink smooth to={"#header"}>
						<img src={require(`../assets/images/logo-white.png`)} alt="" />
					</HashLink>
				) : (
					<a href="https://guil.cl/">
						<img src={require(`../assets/images/logo-black.png`)} alt="" />
					</a>
				)}
				{!props.downloadNavbar ? (
					<DialogStepper component={"free-download-button-navbar"} />
				) : (
					<a
						onClick={emailLinkClickGA}
						href={`mailto:hello@guil.cl`}
						target="_blank"
						rel="noreferrer"
					>
						hello@guil.cl
					</a>
				)}
			</nav>
		</div>
	);
};

export default Navbar;
