import React,{useEffect} from "react";
import ReactGA from "react-ga";
import CallToAction from "../components/CallToAction";
import Description from "../components/Description";
import Header from "../components/Header";
import Preview from "../components/Preview";
import "./Home.css";

const Home = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "Home",
			title: "Home",
		});
	}, []);
	
	return (
		<div className="home-container">
			<Header id="header" />
			<Preview id="preview" />
			<Description id="description" />
			<CallToAction id="callToAction" />
			<div className="background-div"></div>
		</div>
	);
};

export default Home;
