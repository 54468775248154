import React from "react";
import "./StepperTitle.css";

const StepperTitle = props => {
	
	return (
		<div className="stepper-title-container">
			{props.step < 5 ? (
				<section>
					<h2>Download the</h2>
					<h2>Mobility Trends: 2022</h2>
					<h2>güil Mobility Ventures</h2>
				</section>
			) : (
				<section>
					<h1>You're All Set!</h1>
					<p>
						You can download your free copy of "güil Ventures - Mobility Trends 2022".
					</p>
				</section>
			)}
		</div>
	);
};

export default StepperTitle;
