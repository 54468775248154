import React from "react";
import "./StepperProgressBar.css";

const StepperProgressBar = props => {
	
	return (
		<div className="stepper-progress-bar-container">
			<ul>
				<li className="currentCompleted">
					{props.step > 1 ? (
						<img src={require("../assets/icons/check.png")} alt=""></img>
					) : (
						1
					)}
				</li>
				<progress value={props.step > 1 && "100"} max="100" />
				<li className={props.step >= 2 ? "currentCompleted" : ""}>
					{props.step > 2 ? (
						<img src={require("../assets/icons/check.png")} alt=""></img>
					) : (
						2
					)}
				</li>
				<progress value={props.step > 2 && "100"} max="100" />
				<li className={props.step >= 3 ? "currentCompleted" : ""}>
					{props.step > 3 ? (
						<img src={require("../assets/icons/check.png")} alt=""></img>
					) : (
						3
					)}
				</li>
			</ul>
		</div>
	);
};

export default StepperProgressBar;
