import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { motion } from "framer-motion";
import samplePDF from '../assets/documents/whitebook.pdf';
import "./Preview.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Preview = () => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const lastPage = 4;

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
    
    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
      changePage(-1);
    }

    function nextPage() {
      changePage(1);
    }

	return (
		<div className="preview-container">
            <h1>Mobility Trends: 2022 is a complete and comprehensive report that reviewed over 3,000 mobility startups from around the world.</h1>
            <div className='preview-columns-container'>
                <motion.section 
                    className="details-container"
                    initial={{ x: '-150' }}
			    	whileInView={{ x: 0 }}
			    	transition={{type: 'spring', duration:2, bounce: 0.3}}
			    	viewport={{ once: true }}>
			    	<h3>Download this 75 page report to learn more about:</h3>
                    <ul>
                        <li>
                            <img src={require("../assets/images/check.png")} alt="" />
                            <p>The top 3 macro trends</p>
                        </li>
                        <li>
                            <img src={require("../assets/images/check.png")} alt="" />
                            <p>New and innovative business models</p>
                        </li>
                        <li>
                            <img src={require("../assets/images/check.png")} alt="" />
                            <p>Startups that are redefining the sector</p>
                        </li>
                        <li>
                            <img src={require("../assets/images/check.png")} alt="" />
                            <p>Mobility-as-a-Service (MaaS)</p>
                        </li>
                        <li>
                            <img src={require("../assets/images/check.png")} alt="" />
                            <p>Fintech + Mobility</p>
                        </li>
                        <li>
                            <img src={require("../assets/images/check.png")} alt="" />
                            <p>Logistics & Supply Chain</p>
                        </li>
                    </ul>
			    </motion.section>
                <motion.section 
                    className="carousel-container"
                    initial={{ x: '150' }}
			    	whileInView={{ x: 0 }}
			    	transition={{type: 'spring', duration:2, bounce: 0.3}}
			    	viewport={{ once: true }}>
                    <Document file={samplePDF} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber || 1}/>
                    </Document>
                    <div className="page-controls">
                        <button type="button"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}>
                            ‹
                        </button>
                        <span>{pageNumber || (numPages ? 1 : '--')} of {lastPage || '--'}</span>
                        <button type="button"
                                disabled={pageNumber >= lastPage}
                                onClick={nextPage}>
                            ›
                        </button>
                    </div>
	            </motion.section>
            </div>
		</div>
	);
};

export default Preview;
